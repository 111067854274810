<template>
    <v-container>
        <div
            class="d-flex justify-start">
            <v-btn
            @click="()=>{
                $router.go(-1);
            }"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                </div>
            </v-col>
        </v-row>
        <v-card
            style="border-radius:4px 4px 0px 0px"
            v-if="this.refund!=null"
            color="secondary">
            <div
                class="text-h5 pa-5 white--text">
                Refund
            </div>
        </v-card>
        <v-list-item
            class="px-0"
            v-for="(item,index) in this.refund"
            :key="index">
            <v-container
                class="px-0 py-0">
                <v-card
                    style="border-radius:0px 0px 0px 0px">
                    <div
                        class="pa-4">
                        <div
                            class="d-flex justify-start text-h6 mb-4">
                            {{ item.refund_status }}
                        </div>
                        <div
                            class="d-flex justify-start">
                            Amount :
                            <span class="font-weight-bold mx-2">
                                {{ (item.refund_amount!=null)?item.refund_amount:'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            CN No. :
                            <span class="font-weight-bold mx-2">
                                {{ (item.cn_no!=null)?item.cn_no:'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            Refund send date
                            <span class="font-weight-bold mx-2">
                                {{ (item.refund_sent_date!=null)?convertTimeZone(item.refund_sent_date  ):'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            Report date
                            <span class="font-weight-bold mx-2">
                                {{ item.created_at!=null?convertTimeZone(item.created_at):'-' }}
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-container>
        </v-list-item>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        renewalStatusOption:[
            'Pending',
            'Unreachable',
            'Confirm',
            'Confirm - Pending HRDC',
            'Rejected',
        ],
        searchCompanies:null,
        renewalStatusFiltered:null,
        companiesRenewal : null,
        filteredCompanies:null,
       
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        refund:null,
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if (resp.class === "getCompanyRefundByCompany") {
                this.refund = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.$api.fetch(this.fetchCompanyRefund());
        },
        fetchCompanyRefund() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/company/detail/" + this.$router.history.current.params.id;
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LLL");
        }
    }
}
</script>